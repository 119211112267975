<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <h2 class="card-title">Mitarbeiter bearbeiten ({{employee.first_name}} {{employee.last_name}})</h2>
                        <div class="card-tools">
                            <button class="btn btn-secondary btn-sm mr-1" @click="getEmailTemplate('first')" v-if="employee.user === null || employee.user === undefined">Erstmalige Logindaten senden</button>
                            <button class="btn btn-secondary btn-sm mr-1" @click="getEmailTemplate('reset')" v-else>Logindaten zurücksetzen</button>
                            <button class="btn btn-primary btn-sm" @click="updateEmployee()">Speichern</button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6 text-left">
                                <!-- nextEmployee -->
                                <button class="btn btn-sm btn-primary" @click="$parent.previousEmployee()" v-if="previous">
                                    <i class="fas fa-arrow-left"></i>
                                    {{previous.first_name}} {{previous.last_name}}
                                </button>
                            </div>
                            <div class="col-md-6 text-right">
                                <!-- nextEmployee -->
                                <button class="btn btn-sm btn-primary" @click="$parent.nextEmployee()" v-if="next">
                                    {{next.first_name}} {{next.last_name}}
                                    <i class="fas fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="active">Benutzer Aktiv</label>
                                    <select name="active" id="active" class="form-control form-control-sm" v-model="form.active">
                                        <option :value="1">Ja</option>
                                        <option :value="0">Nein</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <fieldset class="p-2 border">
                            <legend class="w-auto">Persönliche Daten</legend>
                                <div class="row">
                                    
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="first_name" class="control-label col-form-label col-form-label-sm">Vorname*</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="first_name" id="first_name" :class="{'is-invalid': form.errors.has('first_name')}" v-model="form.first_name">
                                            <has-error :form="form" field="first_name"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="last_name" class="control-label col-form-label col-form-label-sm">Nachname*</label>
                                            <input type="text" tabindex="4" name="last_name" id="last_name" class="form-control form-control-sm" v-model="form.last_name" :class="{'is-invalid': form.errors.has('last_name')}">
                                            <has-error :form="form" field="last_name"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="number" class="control-label col-form-label col-form-label-sm">Personalnummer</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="number" id="number" :class="{'is-invalid': form.errors.has('number')}" v-model="form.number">
                                            <has-error :form="form" field="number"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="birthday" class="control-label col-form-label col-form-label-sm">Geburtstag</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="birthday" id="birthday" :class="{'is-invalid': form.errors.has('birthday')}" v-model="form.birthday">
                                            <has-error :form="form" field="birthday"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="street" class="control-label col-form-label col-form-label-sm">Straße*</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="street" id="street" :class="{'is-invalid': form.errors.has('street')}" v-model="form.street">
                                            <has-error :form="form" field="street"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="house_number" class="control-label col-form-label col-form-label-sm">Hausnummer*</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="house_number" id="house_number" :class="{'is-invalid': form.errors.has('house_number')}" v-model="form.house_number">
                                            <has-error :form="form" field="house_number"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="zipcode" class="control-label col-form-label col-form-label-sm">PLZ*</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="zipcode" id="zipcode" :class="{'is-invalid': form.errors.has('zipcode')}" v-model="form.zipcode">
                                            <has-error :form="form" field="zipcode"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="city" class="control-label col-form-label col-form-label-sm">Stadt*</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="city" id="city" :class="{'is-invalid': form.errors.has('city')}" v-model="form.city">
                                            <has-error :form="form" field="city"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="email" class="control-label col-form-label col-form-label-sm">E-Mail(privat)*</label>
                                            <input type="email" tabindex="3" class="form-control form-control-sm" name="email" id="email" :class="{'is-invalid': form.errors.has('email')}" v-model="form.email">
                                            <has-error :form="form" field="email"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="business_email" class="control-label col-form-label col-form-label-sm">E-Mail(Beruf)</label>
                                            <input type="email" tabindex="3" class="form-control form-control-sm" name="business_email" id="business_email" :class="{'is-invalid': form.errors.has('business_email')}" v-model="form.business_email">
                                            <has-error :form="form" field="business_email"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="phone" class="control-label col-form-label col-form-label-sm">Telefon</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="phone" id="phone" :class="{'is-invalid': form.errors.has('phone')}" v-model="form.phone">
                                            <has-error :form="form" field="phone"></has-error>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="iban" class="control-label col-form-label col-form-label-sm">IBAN</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="iban" id="iban" :class="{'is-invalid': form.errors.has('iban')}" v-model="form.iban">
                                            <has-error :form="form" field="iban"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="bic" class="control-label col-form-label col-form-label-sm">BIC</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="bic" id="bic" :class="{'is-invalid': form.errors.has('bic')}" v-model="form.bic">
                                            <has-error :form="form" field="bic"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="bank" class="control-label col-form-label col-form-label-sm">Bank</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="bank" id="bank" :class="{'is-invalid': form.errors.has('bank')}" v-model="form.bank">
                                            <has-error :form="form" field="bank"></has-error>
                                        </div>
                                    </div>    
                                </div> 

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="tax" class="control-label col-form-label col-form-label-sm">Steuernummer</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="tax" id="tax" :class="{'is-invalid': form.errors.has('tax')}" v-model="form.tax">
                                            <has-error :form="form" field="tax"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="social_number" class="control-label col-form-label col-form-label-sm">Sozialversicherungsnummer</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="social_number" id="social_number" :class="{'is-invalid': form.errors.has('social_number')}" v-model="form.social_number">
                                            <has-error :form="form" field="social_number"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="nation" class="control-label col-form-label col-form-label-sm">Staatsangehörigkeit</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="nation" id="nation" :class="{'is-invalid': form.errors.has('nation')}" v-model="form.nation">
                                            <has-error :form="form" field="nation"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="confession" class="control-label col-form-label col-form-label-sm">Konfession</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="confession" id="confession" :class="{'is-invalid': form.errors.has('confession')}" v-model="form.confession">
                                            <has-error :form="form" field="confession"></has-error>
                                        </div>
                                    </div>
                                </div>    

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="insurance" class="control-label col-form-label col-form-label-sm">Krankenkasse</label>
                                            <input type="text" tabindex="3" class="form-control form-control-sm" name="insurance" id="insurance" :class="{'is-invalid': form.errors.has('insurance')}" v-model="form.insurance">
                                            <has-error :form="form" field="insurance"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="invalide" class="control-label col-form-label col-form-label-sm">Schwerbehinderung</label>
                                            <select class="form-control form-control-sm" name="invalide" id="invalide" :class="{'is-invalid': form.errors.has('invalide')}" v-model="form.invalide">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="invalide"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="gdb" class="control-label col-form-label col-form-label-sm">Grad der Behinderung(%)</label>
                                            <input type="number" tabindex="3" class="form-control form-control-sm" name="gdb" id="gdb" :class="{'is-invalid': form.errors.has('gdb')}" v-model="form.gdb">
                                            <has-error :form="form" field="gdb"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="showBirthday" class="control-label col-form-label col-form-label-sm">Geburtstag im Kalender?</label>
                                            <select class="form-control form-control-sm" name="showBirthday" id="showBirthday" :class="{'is-invalid': form.errors.has('showBirthday')}" v-model="form.showBirthday">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="showBirthday"></has-error>
                                        </div>
                                    </div>
                                </div>         
                        </fieldset>

                        <fieldset class="p-2 border">
                            <legend class="w-auto">Beschäftigung</legend>
                                <div class="row">
                                    <div class="col-md-3"> 
                                        <div class="form-group">
                                            <label for="useTimeTracking" class="control-label col-form-label col-form-label-sm">Stundenerfassung*</label>
                                            <select class="form-control form-control-sm" name="useTimeTracking" id="useTimeTracking" :class="{'is-invalid': form.errors.has('useTimeTracking')}" v-model="form.useTimeTracking">
                                                <option value="1">Ja</option>
                                                <option value="0">Nein</option>
                                            </select>
                                            <has-error :form="form" field="useTimeTracking"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="holidayDays">Urlaubstage</label>
                                        <input type="number" min="0" class="form-control form-control-sm" v-model="form.holiday_days" name="holidayDays" id="holidayDays" :class="{'is-invalid': form.errors.has('holiday_days')}">
                                        <has-error :form="form" field="holiday_days"></has-error>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="rest_holidays">Resturlaub vom Vorjahr*</label>
                                        <input type="number" min="0" class="form-control form-control-sm" v-model="form.rest_holidays" name="rest_holidays" id="rest_holidays" :class="{'is-invalid': form.errors.has('rest_holidays')}">
                                        <has-error :form="form" field="rest_holidays"></has-error>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="type" class="control-label col-form-label col-form-label-sm">Beschäftigungsart*</label>
                                            <select class="form-control form-control-sm" name="type" id="type" :class="{'is-invalid': form.errors.has('type')}" v-model="form.type">
                                                <option value="vollzeit">Vollzeit</option>
                                                <option value="teilzeit">Teilzeit</option>
                                                <option value="gfb">GFB</option>
                                                <option value="agenten">Agenten</option>
                                                <option value="azubi">Azubi</option>
                                            </select>
                                            <has-error :form="form" field="type"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="base_time" class="control-label col-form-label col-form-label-sm">Basisstunden*</label>
                                            <input type="number" tabindex="3" class="form-control form-control-sm" name="base_time" id="base_time" :class="{'is-invalid': form.errors.has('base_time')}" v-model="form.base_time">
                                            <has-error :form="form" field="base_time"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="tax_class" class="control-label col-form-label col-form-label-sm">Steuerklasse*</label>
                                            <select class="form-control form-control-sm" name="tax_class" id="tax_class" :class="{'is-invalid': form.errors.has('tax_class')}" v-model="form.tax_class">
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                            </select>
                                            <has-error :form="form" field="tax_class"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="employee_groups_id" class="control-label col-form-label col-form-label-sm">Position*</label>
                                            <!-- <input type="text" tabindex="3" class="form-control form-control-sm" name="position"   v-model="form.position"> -->
                                            <select class="form-control" v-model="form.employee_groups_id" :class="{'is-invalid': form.errors.has('employee_groups_id')}" id="employee_groups_id">
                                                <option :value="null">-- Bitte Gruppe wählen --</option>
                                                <option v-for="group in employeesGroups" :key="group.id" :value="group.id">{{group.name}}</option>
                                            </select>
                                            <has-error :form="form" field="employee_groups_id"></has-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="start_date" class="control-label col-form-label col-form-label-sm">Eintrittsdatum</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="start_date" id="start_date" :class="{'is-invalid': form.errors.has('start_date')}" v-model="form.start_date">
                                            <has-error :form="form" field="start_date"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="stop_date" class="control-label col-form-label col-form-label-sm">Austrittsdatum</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="stop_date" id="stop_date" :class="{'is-invalid': form.errors.has('stop_date')}" v-model="form.stop_date">
                                            <has-error :form="form" field="stop_date"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="limitation_date" class="control-label col-form-label col-form-label-sm">Befristungsende</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="limitation_date" id="limitation_date" :class="{'is-invalid': form.errors.has('limitation_date')}" v-model="form.limitation_date">
                                            <has-error :form="form" field="limitation_date"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-3">      
                                        <div class="form-group">
                                            <label for="trial_time_date" class="control-label col-form-label col-form-label-sm">Probezeitende</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="trial_time_date" id="trial_time_date" disabled :class="{'is-invalid': form.errors.has('trial_time_date')}" v-model="form.trial_time_date">
                                            <has-error :form="form" field="trial_time_date"></has-error>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="inactive_start" class="control-label col-form-label col-form-label-sm">Ruhend ab</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="inactive_start" id="inactive_start" :class="{'is-invalid': form.errors.has('inactive_start')}" v-model="form.inactive_start">
                                            <has-error :form="form" field="inactive_start"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label for="inactive_stop" class="control-label col-form-label col-form-label-sm">Ruhend bis</label>
                                            <input type="date" tabindex="3" class="form-control form-control-sm" name="inactive_stop" id="inactive_stop" :class="{'is-invalid': form.errors.has('inactive_stop')}" v-model="form.inactive_stop">
                                            <has-error :form="form" field="inactive_stop"></has-error>
                                        </div>
                                    </div>
                                </div>
                        </fieldset>

                        <fieldset class="p-2 border">
                            <legend class="w-auto">Gehalt/Lohn</legend>
                            <div class="row">
                                <div class="col-md-3">      
                                    <div class="form-group">
                                        <label for="salary" class="control-label col-form-label col-form-label-sm">Gehalt</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="salary" id="salary" disabled :class="{'is-invalid': form.errors.has('salary')}" v-model="form.salary">
                                        <has-error :form="form" field="salary"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">      
                                    <div class="form-group">
                                        <label for="rate" class="control-label col-form-label col-form-label-sm">Stundenlohn</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="rate" id="rate" disabled :class="{'is-invalid': form.errors.has('rate')}" v-model="form.rate">
                                        <has-error :form="form" field="rate"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">      
                                    <div class="form-group">
                                        <label for="sach" class="control-label col-form-label col-form-label-sm">zzgl. Sachzulage</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="sach" id="sach" :class="{'is-invalid': form.errors.has('sach')}" v-model="form.sach">
                                        <has-error :form="form" field="sach"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">      
                                    <div class="form-group">
                                        <label for="vwl" class="control-label col-form-label col-form-label-sm">VWL</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="vwl" id="vwl" :class="{'is-invalid': form.errors.has('vwl')}" v-model="form.vwl">
                                        <has-error :form="form" field="vwl"></has-error>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-3">      
                                    <div class="form-group">
                                        <label for="bav_an" class="control-label col-form-label col-form-label-sm">BAV (AN)</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="bav_an" id="bav_an" :class="{'is-invalid': form.errors.has('bav_an')}" v-model="form.bav_an">
                                        <has-error :form="form" field="bav_an"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="bav_ag" class="control-label col-form-label col-form-label-sm">BAV (AG)</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="bav_ag" id="bav_ag" :class="{'is-invalid': form.errors.has('bav_ag')}" v-model="form.bav_ag">
                                        <has-error :form="form" field="bav_ag"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="bav_ag_frei" class="control-label col-form-label col-form-label-sm">BAV (AG) Freiwillig</label>
                                        <input type="number" tabindex="3" class="form-control form-control-sm" name="bav_ag_frei" id="bav_ag_frei" :class="{'is-invalid': form.errors.has('bav_ag_frei')}" v-model="form.bav_ag_frei">
                                        <has-error :form="form" field="bav_ag_frei"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="free_rv" class="control-label col-form-label col-form-label-sm">RV Befreiung</label>
                                        <select class="form-control form-control-sm" name="free_rv" id="free_rv" :class="{'is-invalid': form.errors.has('free_rv')}" v-model="form.free_rv">
                                            <option value="1">Ja</option>
                                            <option value="0">Nein</option>
                                        </select>
                                        <has-error :form="form" field="free_rv"></has-error>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset class="p-2 border">
                            <legend class="w-auto">Qualifikation</legend>
                            <div class="row">
                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="ada" class="control-label col-form-label col-form-label-sm">AdA Schein</label>
                                        <select class="form-control form-control-sm" name="ada" id="ada" :class="{'is-invalid': form.errors.has('ada')}" v-model="form.ada">
                                            <option value="1">Ja</option>
                                            <option value="0">Nein</option>
                                        </select>
                                        <has-error :form="form" field="ada"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="infection" class="control-label col-form-label col-form-label-sm">Belehrung (Infektionsschutz)</label>
                                        <select class="form-control form-control-sm" name="infection" id="infection" :class="{'is-invalid': form.errors.has('infection')}" v-model="form.infection">
                                            <option value="1">Ja</option>
                                            <option value="0">Nein</option>
                                        </select>
                                        <has-error :form="form" field="infection"></has-error>
                                    </div>
                                </div>

                                <div class="col-md-3"> 
                                    <div class="form-group">
                                        <label for="first_aid" class="control-label col-form-label col-form-label-sm">Erste Hilfe Schein</label>
                                        <select class="form-control form-control-sm" name="first_aid" id="first_aid" :class="{'is-invalid': form.errors.has('first_aid')}" v-model="form.first_aid">
                                            <option value="1">Ja</option>
                                            <option value="0">Nein</option>
                                        </select>
                                        <has-error :form="form" field="first_aid"></has-error>
                                    </div>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset>
                            <legend class="w-auto">Kasse</legend>
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label col-form-label col-form-label-sm">Kasse</label>
                                        <select class="form-control form-control-sm" v-model="form.posEnable">
                                            <option :value="1">Ja</option>
                                            <option :value="0">Nein</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label col-form-label col-form-label-sm">PIN*</label>
                                        <input type="password" class="form-control form-control-sm" v-model="form.posPin" :disabled="!form.posEnable" :class="{'is-invalid': form.errors.has('posPin')}">
                                        <has-error :form="form" field="posPin"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label col-form-label col-form-label-sm">PIN Wiederholen*</label>
                                        <input type="password" class="form-control form-control-sm" v-model="form.posPin_confirmation" :disabled="!form.posEnable" :class="{'is-invalid': form.errors.has('posPin_confirmation')}">
                                        <has-error :form="form" field="posPin_confirmation"></has-error>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="control-label col-form-label col-form-label-sm">&nbsp;</label> <br>
                                        <button class="btn btn-secondary btn-xs mr-1" @click="fillPin()" :disabled="!form.posEnable">
                                            <i class="fas fa-fill"></i>
                                        </button>
                                        <label class="control-label col-form-label col-form-label-sm mr-1">PIN vorausfüllen</label>
                                        <!-- <i class="fas fa-question-circle" id="tooltip-pin"></i>
                                        <b-tooltip target="tooltip-pin" triggers="hover">
                                            Dieser füllt die Pin eingaben Standartmäßig mit 1111 aus.
                                        </b-tooltip>   -->
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <b-modal id="mail" title="Logindaten senden" ok-only ok-variant="primary" ok-title="Senden" size="xl" @ok="sendNotification">
            <div class="form-group">
                <ckeditor :editor="editor" :config="editorConfig" v-model="template"></ckeditor>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import ClassicEditor from './../../../assets/packages/ckeditor5/src/ckeditor';
export default {

    props: [
        'employee',
        'next',
        'previous'
    ],

    data(){
        return{
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            form: new window.Form({
                first_name: "",
                last_name: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                iban: "",
                bic: "",
                bank: "",
                email: "",
                user_id: "",
                base_time: null,
                tax: "",
                number: "",
                start_date: "",
                stop_date: "",
                limitaion_date: "",
                trial_time_date: "",
                position: "",
                employee_groups_id: null,
                birthday: "",
                phone: "",
                business_email: "",
                salary: null,
                rate: null,
                sach: null,
                vwl: null,
                bav_an: null,
                bav_ag: null,
                bav_ag_frei: null,
                insurance: "",
                social_number: "",
                free_rv: 0,
                tax_class: 1,
                ada: 0,
                infection: 0,
                first_aid: 0,
                invalide: 0,
                gdb: "",
                confession: "",
                nation: "",
                type: "",
                useTimeTracking: 0,
                holiday_days: 0,
                rest_holidays: 0,
                inactive_start: null,
                inactive_stop: null,
                active: true,
                posEnable: false,
                posPin: null,
                posPin_confirmation: null,
                showBirthday: 0,
            }),
            employeesGroups: {},
            template: "",
        }
    },
    watch: {
        'form.posEnable': {
            handler() {
                if(this.form.posEnable == false)
                {
                    this.form.posPin = null;
                    this.form.posPin_confirmation = null;                    
                }
            },
            deep: true,
        },
        'employee': {
            handler() {
                this.form.fill(this.employee);
            },
            deep: true,
        }
    },
    methods:{
        fillPin() {
            if(this.form.posEnable == 1)
            {
                this.form.posPin = "1111";
                this.form.posPin_confirmation = "1111";
            }
        },
        updateEmployee(){
            this.form
                .put("/employees/"+this.employee.id)
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Änderungen gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.employee = response.data.data;
                    this.form.fill(this.employee);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufennnnn",
                    });
                });
        },
        getEmployeegroups()
        {
            this.axios
                .get("/employee/groups", {
                    params: {
                        per_page: 10000
                    }
                })
                .then((response) => {
                    this.employeesGroups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist wohl etwas schief gelaufen"
                    });
                })
        },
        getEmailTemplate(type)
        {
            this.axios
                .post("/employee/getMail/" + this.employee.id, {
                    type
                })
                .then((response) => {
                    this.$bvModal.show('mail');
                    this.template = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der E-Mail ist etwas schief gelaufen'
                    })
                });
        },
        sendNotification() {
            this.axios
                .post("employee/sendMail/" + this.employee.id, {
                    content: this.template
                })
                .then((response) => {
                    this.employee = response.data.data;
                    this.form.fill(this.employee);
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim senden der E-Mail ist etwas schief gelaufen'
                    })
                })
        }
    },

    mounted(){
        this.getEmployeegroups();
        this.form.fill(this.employee);
    }

}
</script>

<style>

</style>